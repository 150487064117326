
@import url('https://fonts.googleapis.com/css2?family=Source+Serif+4:wght@300;400;500;600;700&display=swap');
body{
    margin: 0;
    font-family: 'Source Serif 4', serif;
    background: var(--bg);
    transition: all 3ms ease;
    color: var(--black);
    scroll-behavior: smooth;
}

