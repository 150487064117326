.container {
  padding: 1.5rem;
  display: flex;
}

.logo {
  display: flex;
  align-items: center;
  flex: 1;
  gap: 1rem;
}

.logo > img {
  width: 2.5rem;
  height: 2.5rem;
}

.logo > span {
   font-weight: 700;
  }

  .right{
    display: flex;
    gap: 2rem;
    align-items: center;
    justify-content: center;
  }

  .menulist{
    display: flex;
    gap: 2rem;
    font-weight: 500;
    list-style: none;
  }

  .menulist>li:hover{
    color: #fe956f;
    cursor: pointer;
  }

  .search{
    width: 7rem;
    outline: none;
    border: none;
    border-radius: 5px;
    padding: 0.5rem;
    height: 30%;
  }

  .cart{
    width: 1.5rem;
    height: 1.5rem;
    cursor: pointer;
  }

  .bars{
    display: none;
  }

  @media screen and (max-width: 640px) {
    .right{
      position: absolute;
      right: 2rem;
      z-index: 9999;
      background: white;
      color: var(--black);
      border-radius: 5px;
      flex-direction: column;
      padding: 1rem;
    }

    .menulist{
      /* display: flex; */
      flex-direction: column;
      margin-left: -2rem;
      display: none;
      
    }

      .menulist>li{
        margin-bottom: 1rem;
      }

    .search{
      display: none;
    }

    .bars{
      display: block;
    }
  }


  @media screen and (max-width: 856px) {
    
    .search{
      display: none;
    }

    .right{
      gap: 1rem;
    }
  }

